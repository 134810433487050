import { type FC } from 'react';
import logoWhiteSVG from '@web/assets/logo-selfstock-white.svg';
import logoBlackSVG from '@web/assets/logo-selfstock.svg';
import Image from 'next/image';

type Props = {
  intent?: 'black' | 'white';
  className?: string;
  height?: number;
  width?: number;
};

export const Logo: FC<Props> = function ({ className = '', intent = 'white', height, width }) {
  return (
    <Image
      className={className}
      src={intent === 'white' ? logoWhiteSVG : logoBlackSVG}
      alt="Logo Selfstock.com"
      height={height}
      width={width}
    />
  );
};
