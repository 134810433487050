import { type FC, type ReactNode } from 'react';
import { MainMenu } from '@web/components/menu/main-menu';
import { Footer } from '@web/components/footer/footer';
import { ZodI18n } from '@web/components/zod-i18n';
import { GoogleTagManager } from '@next/third-parties/google';
import { Toaster as HotToaster } from "react-hot-toast";
import { Toaster } from '@web/components/ui/sonner';
import { isProduction } from '@web/lib/utils';

type Props = {
  children: ReactNode;
};

export const MainLayout: FC<Props> = function ({ children }) {
  return (
    <body className="main-menu-offset" suppressHydrationWarning>

      {/* Google Tag Manager GTM */}
      <GoogleTagManager gtmId={isProduction() ? "GTM-T3WHJPDR" : ""} />

      <ZodI18n />

      <MainMenu />
      {children}
      <Footer />

      <HotToaster
        position="bottom-left"
      />

      <Toaster />
    </body>
  );
};
