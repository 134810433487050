import Link from "next/link";
import type { FC } from "react";

export const SubFooter: FC = function() {
  const fullYear = new Date().getFullYear();

  return <div
  className={
    'container mx-auto grid gap-8 px-4 py-8 text-center font-semibold lg:grid-cols-2 lg:text-left'
  }>
  <div>© selfstock.com {fullYear}, tous droits réservés</div>

  <div
    className={
      'flex flex-col justify-end space-y-6 lg:flex-row lg:space-x-4 lg:space-y-0'
    }>
    <Link className="hover:text-brand transition-colors" href={'/mentions-legales'}>Mentions légales</Link>

    <Link className="hover:text-brand transition-colors" href={'/mentions-legales/declaration-de-confidentialite'}>
      Politique de confidentialité
    </Link>
  </div>
</div>
};